<template> 
    <the-layout-without-login>
           <form @submit.prevent="login" class="mt-4 mb-4">
                             <CRow class="mb-5">
                                <CCol><h3 class="text-left" style="font-weight: bold;">Log in</h3>  </CCol>
                                <CCol class="text-right mt-2 mr-2"><router-link to="/resendmail" class="text-dark"><strong>忘記密碼</strong></router-link></CCol>
                            </CRow> 
                            <CInput v-model="username" placeholder="帳號" autocomplete="username email">
                                <template #prepend-content>
                                    <CIcon name="cil-user" />
                                </template>
                            </CInput>
                            <CInput v-model="password" placeholder="密碼" type="password" autocomplete="curent-password">
                                <template #prepend-content>
                                    <CIcon name="cil-lock-locked" />
                                </template>
                            </CInput>
                             <!--
                            <CRow>
                                <CCol col="6">
                                    <input id="keep-user-logged-in" name="keep-user-logged-in" type="checkbox" value="">
                                    <label for="keep-user-logged-in" class="form-check-label">
                                        Keep me logged in
                                    </label>
                                </CCol>
                                <CCol col="6" class="text-right">
                                    <CButton color="link" class="px-0">Forgot password?</CButton>
                                </CCol>
                            </CRow>
                             -->
                             
                            <div class="text-center mt-5">
                                <button type="submit" class="btn btn-success btn-lg text-white btn-block">登入</button>  
                            </div>
                            <!--
                            <br />
                            <br />
                            <br />
                            <div class="form-or-separator">
                                <span class="partial-separator"></span>
                                <span class="separator-text"> or </span>
                                <span class="partial-separator"></span>
                            </div> 
                            <div class="text-center">
                                <GoogleLogin ref="GoogleLogin" />
                            </div> 
                            -->
                        </form> 
                        <p class="text-center mt-5">
                                不是會員？  <router-link to="/register"><strong class="text-default">立即註冊</strong></router-link> 
                        </p>  
                        <CModal
                                title=""
                                color="warning" 
                                :show.sync="IsSysMaintain"> 
                                <h5 class="text-center" style="color:rgb(192,0,0)"><strong>平台停機通知!</strong></h5><br/>
                                <div class="text-center">
                                    <p class="mt-2"><span style="font-size:1rem" class="text-danger">{{SysMaintainDate}}</span></p> 
                                    <p>親愛的用戶您好，</p>
                                    <p>由於平台進行系統更新作業，屆時將暫停各項服務，<Br/> 造成您的不便，敬請見諒!!! 謝謝</p>
                                    
                                </div>
                                  <template #header>
                                   <div></div>
                                </template>
                                <template #footer>
                                    <CButton @click="IsSysMaintain=false" color="warning"><span class="text-white">關閉</span>
                                    </CButton>
                                </template>
                        </CModal>  
       
    </the-layout-without-login>
</template>

<script>

import TheLayoutWithoutLogin from '../../containers/TheLayoutWithoutLogin.vue'
import axios from 'axios';
import appSettings from '../../util/settings';
import store from '../../util/store.js';
import dialog from '../../util/dialog';

//const GoogleLogin = () => import('@/views/account/GoogleLogin')

export default {
    components: {
        TheLayoutWithoutLogin
        //GoogleLogin
    },
    data() { 
        return {
            username:'',
            password:'',
            
            IsSysMaintain:(appSettings.IsSysMaintain ==='true'), 
            SysMaintainDate:appSettings.SysMaintainDatetime
        }
    },
    mounted(){
    },
    methods: {       
        loginResult(response){   
            var data = response.data;
            if(data.IsLogin){
                store.commit("clearTryFree");
                store.commit("setUser", data);
                // this.$router.replace({ name: "MarketReport" });
                const currentUser = store.state.userName;
                
                this.$router.push({
                    name:'MarketReport',
                    params:{
                    }
                });
            }
            else{
                dialog.showInforDialog(data.Message);
            }
        },
        login() {
            var loginResult = this.loginResult;

            axios.post(appSettings.LoginDb, 
                {
                    userId : this.username,
                    password : this.password
                })
                .then(loginResult)
                .catch(function (error) { 
                    dialog.showInforDialog(error);
                    console.log(error);
            });
        } 
    },
    name: 'Login'
}
</script>

<style>
  body {
    background-color: white;
  }
</style>